import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import Axios from 'axios';

const DatatablePage = () => {
const user = localStorage.getItem('aprofile');
const name = JSON.parse(user)
const ademail = name.result.email;
const [userdata, setUserdata]= useState();
useEffect(()=>{
    Axios.post('https://backend.mafacturedevis.com/admin/allusers',{
                  'email':ademail,
                }).then(response => {       
                    setUserdata(response.data.alluser);
                }).catch(error => {
                  console.log(error.response)
                });   
},[])
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Subscription(1/0)',
        field: 'subscriptionStatus',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Renewal date',
        field:'endDate',
        width:200
      }
    ],
    rows:userdata,
  };

  return (
    <MDBDataTable
      striped
      bordered
      small
      data={data}
    />
  );
}

export default DatatablePage;