/*
 **Author: Santosh Kumar Dash
 **Author URL: http://santoshdash.epizy.com/
 **Github URL: https://github.com/quintuslabs/dashio-admin
 */

import React, {useState} from "react";
import { Button, Form } from "react-bootstrap";
import "./Pages.css";
import axios from 'axios';
import {Alert} from '../Alert'


const Pages = () => {

  const [email, setEmail] = useState();
  const [idd, setIdd] = useState();
  const [usrdate, setUsrdate] = useState();
  const [code, setCode] = useState();
  const [errord, setErrord] = useState();
  const [bool, setBool] = useState();
  const [alertcs, setAlertcs] = useState();

  const handleUpdateuser = (e) =>{

    axios.post('https://backend.mafacturedevis.com/admin/updateUser', {
      email: email,
      date: usrdate,
      subscriptionCode: idd,
      adCode:code
    }).then((response) =>{
      console.log(response.data.message)
      if(response.data){
        console.log(response.data.message)
        setErrord(response.data.message)
        setBool(true);
        setAlertcs('alert-success');
        }
    }).catch(error =>{
      console.log(error.response)
      if(error.response){
        console.log(error.response.data.message)
        setErrord(error.response.data.message)
        setBool(true);
        setAlertcs('alert-danger');
        }
    })
   setEmail('')
   setIdd('')
   setUsrdate('')
   setCode('')
  }


  return (
    <div>
      <div class="container col-xl-4">
      <Alert
       children={errord}
       dismissible={bool}
       className={alertcs}
       />
        <h3 bold text-center>
          Update user subscription
        </h3>
        <br></br>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control value={email} onChange={e => setEmail(e.target.value)} required type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="number">
            <Form.Label>Subscription Status</Form.Label>
            <Form.Control value={idd} onChange={e => setIdd(e.target.value)} required type="number" placeholder="Subscription status" max={1} maxLength={1}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="date">
            <Form.Label>Subscription End Date</Form.Label>
            <Form.Control value={usrdate} onChange={e => setUsrdate(e.target.value)} required type="date" placeholder="End date" max={1} maxLength={1} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Label>Admin Code</Form.Label>
            <Form.Control value={code} onChange={e => setCode(e.target.value)} required type="password" placeholder="Admin code" />
          </Form.Group>
          <Button onClick={(e) =>handleUpdateuser(e)} text-center variant="primary">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Pages;
