import Axios from 'axios';
import React, { useEffect, useState} from 'react'
import {Alert} from '../Alert'
import { useHistory } from 'react-router-dom'


const Signin = () => {
  const history = useHistory();
const [emailid, setEmailId] = useState();
const [password, setPassword] = useState();
const [secreteid, setSecreteId] = useState();
const [errord, setErrord] = useState();
const [bool, setBool] = useState();
const [alertcs, setAlertcs] = useState();
const user = localStorage.getItem('aprofile')

useEffect(()=>{
  if(user){
    history.push('/mafacture-admin/dashboard')
  }
})

const handleSubmit = () =>{
    Axios.post('https://backend.mafacturedevis.com/admin/signin', {
    email: emailid,
    password: password,
    secrete: secreteid
  })
  .then(function (response) {
    if(response.data){
    localStorage.setItem('aprofile', JSON.stringify({...response.data}))
    window.location.replace('https://admin.mafacturedevis.com/mafacture-admin/dashboard')
    }
  }).catch(function(error){
    if(error.response){
    console.log(error.response.data.message)
    setErrord(error.response.data.message)
    setBool(true);
    setAlertcs('alert-danger');
    }
  })
}


    return (
        <div className='d-flex align-self-stretch'>
     <div className='col-xl-4 col-md-3 col-sm-3 container'>
     <Alert
       children={errord}
       dismissible={bool}
       className={alertcs}
       />
       <img src='./Roi-Pdv.png' style={{height: '300px'}} alt='Company logo'/>
      <form style={{paddingTop:'15px'}}>
        <h3 className='text-center'>Sign In</h3>
        <div className="mb-3">
          <label>Email address</label>
          <input
            value={emailid}
            onChange={(e) => setEmailId(e.target.value)}
            type="email"
            className="form-control"
            placeholder="Enter email"
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="form-control"
            placeholder="Enter password"
          />
        </div>
        <div className="mb-3">
          <label>Admin code</label>
          <input
            value={secreteid}
            onChange={(e) => setSecreteId(e.target.value)}
            type="password"
            className="form-control"
            placeholder="Enter code"
          />
        </div>
        <div className="d-grid text-center">
          <button type="button" action='submit' onClick={handleSubmit} className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
      </div>
      </div>
    );
  }

export default Signin