import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { BaseLayout } from "./layouts";
// Route Views
import Dashboard from "./views/Dashboard/Dashboard";
import Pages from "./views/Pages/Pages";
import Signin from "./views/Signin/Signin";

var routes = [
  {
    path:"/",
    exact: true,
    layout: Signin,
    component:Signin,
  },
  {
    path: "/mafacture-admin",
    exact: true,
    layout: BaseLayout,
    component: () => <Redirect to="/mafacture-admin/dashboard" />,
  },
  {
    path: "/mafacture-admin/dashboard",
    layout: BaseLayout,
    component: Dashboard,
  },
  {
    path: "/mafacture-admin/pages",
    layout: BaseLayout,
    component: Pages,
  },
];

export default routes;
