import React from "react";
import { useHistory } from 'react-router-dom'
import "./StyleSheets/Dashboard.css";
import DashboardHeaderSection from "./Components/DashboardHeaderSection";
import Sortable from "./Components/Sortable";
// import EditableTable from "./Components/EditableTable"


const Dashboard = ()=> {
const history = useHistory();
const user = localStorage.getItem('aprofile');
const name = JSON.parse(user)
const adname = name.result.name;
if(!user){
  history.push('/')
}
    return (
      <div className="main-content-container p-4 container-fluid">
        <div className="right-panel">
          <h2 className="text-center">Welcome! {adname}  </h2>
          <DashboardHeaderSection />
          <Sortable />
        </div>
      </div>
    );
  }


export default Dashboard;
