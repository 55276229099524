/*
 **Author: Santosh Kumar Dash
 **Author URL: http://santoshdash.epizy.com/
 **Github URL: https://github.com/quintuslabs/dashio-admin
 */

import React, { useEffect, useState } from "react";
import DashboardHeaderCard from "./DashboardHeaderCard";
import Axios from 'axios';

const DashboardHeaderSection = () => {

  const [userdata, setUserdata]= useState();
  const [revenuser, setRevenUser] = useState();
useEffect(()=>{
    Axios.post('https://backend.mafacturedevis.com/admin/allusers',{
                  'email':"rankrish13@gmail.com",
                }).then(response => {      
                    setUserdata(response.data.alluser.length);
                    const rvnusr = response.data.alluser.filter(data => data.subscriptionStatus === 1);
                    setRevenUser(rvnusr.length)
                }).catch(error => {
                  console.log(error.response)
                });   
},[])
 
    return (
      <div className="row">
        <DashboardHeaderCard
          count={userdata}
          title={"Clients"}
          icon={"users"}
          color={"flat-color-3"}
        />

        <DashboardHeaderCard
          count={revenuser}
          title={"Revenue clients"}
          icon={"money"}
          color={"flat-color-4"}
        />
      </div>
    );
  }


export default DashboardHeaderSection;
