/*
 **Author: Santosh Kumar Dash
 **Author URL: http://santoshdash.epizy.com/
 **Github URL: https://github.com/quintuslabs/dashio-admin
 */

import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./StyleSheets/Sidebar.css";
function Sidebar(props) {
  const history = useHistory();
  const [active, setActive] = useState("");
  // const [dropdownToggle, setDropDownToggle] = useState(false);
  useEffect(() => {
    setActive(props.location.pathname);
  }, [props.location.pathname, active]);

  const logout = () => {
    localStorage.removeItem('aprofile');
    history.push('/')
  }
  return (
    <div
      className="sidebar-container border-right main-sidebar"
      id="sticky-sidebar"
    >
      <nav id="sidebar" className={props.toggleClass}>
        <ul className="list-unstyled components">
          <li
            className={active === "/mafacture-admin/dashboard" ? "active" : null}
          >
            <a href="/mafacture-admin/dashboard">
              <div className="menu-icon">
                <i className="fa fa-home nav_icon" aria-hidden="true"></i>
              </div>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          <li className={active === "/mafacture-admin/pages" ? "active" : null}>
            <a href="/mafacture-admin/pages">
              <div className="menu-icon">
                <i
                  className="fa fa-file-text-o nav_icon"
                  aria-hidden="true"
                ></i>
              </div>
              <span className="menu-title">Update user</span>
            </a>
          </li>

          <li
          >
              <a onClick={logout} href="/">
              <div className="menu-icon">
                <i
                  className="fa fa-power-off nav_icon"
                  aria-hidden="true"
                ></i>
              </div>
              <span className="menu-title">Logout</span>
            </a>
          </li>

          
        </ul>
      </nav>
    </div>
  );
}

export default withRouter(Sidebar);
